import React,{useEffect,useState} from 'react';
import {
    Col,
    Card,
    CardImgOverlay,
    CardBody,
    CardImg,
    Row,
    Button
} from "reactstrap";
import Panel from "../../../../shared/components/Panel";
import {connect} from "react-redux";
import ModelList from '../../../../Models/Lists/List';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import {setPriceHistoryRange} from "../../../../redux/actions/themeActions";
Chart.register(...registerables);

const ListPriceHistory = ({dispatch,listData}) => {

    const mv = new ModelList();
    const [data,setData] = useState(false);
    const [historyRange,setHistoryRange] = useState(30);
    const [error,setError] = useState(false);

    useEffect(()=>{

        //GET THE DATA
        mv.getPriceHistory(listData.l_id,historyRange).then((res)=>{

            if (res.data.status === 'failed'){
                setError(res.data.msg);
                return;
            }

            let linePoints=[];
            let xaxis = [];

            res.data.data.forEach((price)=>{
                 linePoints.push(price.lst_price);
                 xaxis.push(price.lst_created);
             });

            setData({
                labels: xaxis,
                datasets: [
                    {
                        label: 'List',
                        fill: false,
                        lineTension: 0.3,
                        backgroundColor: '#FFCE56',
                        borderColor: '#FFCE56',
                        borderWidth: 1,
                        pointBackgroundColor: '#FFCE56',
                        pointHoverRadius: 5,
                        pointHoverBorderWidth: 1,
                        pointRadius: 4,
                        pointHitRadius: 10,
                        data: linePoints,
                    },
                    // {
                    //     label: 'Green',
                    //     fill: false,
                    //     lineTension: 0.3,
                    //     backgroundColor: '#4BC0C0',
                    //     borderColor: '#4BC0C0',
                    //     borderWidth: 1,
                    //     pointBackgroundColor: '#4BC0C0',
                    //     pointHoverRadius: 6,
                    //     pointHoverBorderWidth: 1,
                    //     pointRadius: 5,
                    //     pointHitRadius: 10,
                    //     data: [52, 47, 45, 4, 58, 7, 45],
                    // },
                ],
            });
        });
    },[historyRange]);

    const onDateRangeChange=(e)=>{
        // TODO: GET LIST PRICE HISTORY. WRITE A FUNCTION FOR IT IN THE MODEL
        setHistoryRange(e.target.value);
    };

    const options = {
        legend: {
            position: 'bottom',
        },
        scales: {
            xAxes: [
                {
                    type: "time",
                    time: {
                        unit: 'day',
                        unitStepSize: 2
                    },
                    gridLines: {
                        color: 'rgb(204, 204, 204)',
                        borderDash: [3, 3],
                    },
                    ticks: {
                        autoSkip: true,
                        fontColor: 'rgb(204, 204, 204)',
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        color: 'rgb(204, 204, 204)',
                        borderDash: [3, 3],
                    },
                    ticks: {
                        autoSkip: true,
                        fontColor: 'rgb(204, 204, 204)',
                    },
                },
            ],
        },
    };

    return (
        <div>
            {data !== false ? (
                <>
                    <div className="card__title">
                        <Row>
                            <Col xs={12} className="mb-1">
                                <h5 className="bold-text mr-auto">Price History</h5>
                            </Col>
                            <Col xs={12} className="mb-1">
                                <h6>the chart below is allows you to see the price history of a list. Use the dropdown to select the date range</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="mb-1">
                                <select onChange={onDateRangeChange}>
                                    <option value="30">30 Days</option>
                                    <option value="60">60 Days</option>
                                    <option value="180">6 Months</option>
                                    <option value="365">1 Year</option>
                                    <option value="730">2 Years</option>
                                </select>
                            </Col>
                        </Row>

                    </div>
                    <Line data={data} options={options} />
                </>
            ) : error !== false ? (
                <h5 className="bold-text mr-auto">{error}</h5>
            ) : null}
        </div>
    );

};

export default connect(state => ({
    listData: state.list.listData,
    ListPriceHistoryRange: state.theme.ListPriceHistoryRange,
    ratingHistoryRange: state.theme.ratingHistoryRange
}))(ListPriceHistory);

