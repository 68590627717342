import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody , CardTitle,
} from 'reactstrap';
import ModelProducts from '../../../../Models/Products';
import {Link, useParams} from 'react-router-dom';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../../../shared/components/Panel";
import {showNotification} from "../../../../shared/components/Notification";
import LoadingIcon from 'mdi-react/LoadingIcon';
import ListItem from "../../../../shared/components/Lists/ListItem";

const LatestLists = ({ dispatch,latestLists,rtl,theme }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const lsm = new ModelProducts();

    useEffect(() => {
        // NOT NEEDED SINCE THE INITIAL LOAD IS HANDLED BY PARENT COMPONENT FOR ALL LISTS
        setIsLoaded(true)

    },[]);


    return (
        <Row>
            {!isLoaded &&
                <div className="panel__refresh"><LoadingIcon/></div>
            }
            {isLoaded &&

                <div className="listsContainer scrolling-wrapper-flexbox mb-4">
                    {typeof latestLists !=="undefined" && latestLists!== null && latestLists.map((list,i)=>(
                        <div key={i} className="bsCardV6 col-10 col-sm-5 col-md-3">
                            <ListItem list={list}/>
                        </div>
                    ))}
                </div>

            }
        </Row>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    latestLists: state.lists.latest
    // showVenueMenu:state.venue.showVenueMenu
}))(LatestLists);