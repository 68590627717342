import React,{ useState,useEffect } from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelList from '../../Models/Lists/List';
import ProductsTable from "../../shared/components/ProductsTable";
import {connect} from "react-redux";
import ProductsWidget from "../../shared/components/ProductsWidget";
import Authentication from "../../Models/Authentication";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/fontawesome-free-solid";
import {faArrowDown, faArrowUp, faChartBar, faEdit, faMoneyBill, faTrash} from "@fortawesome/free-solid-svg-icons";
import store from "../App/store";
import {setListData} from "../../redux/actions/Lists/listActions";
import {showNotification} from "../../shared/components/Notification";
import MultipleImageUpload from "../../shared/components/MultipleImageUpload";
import ListImages from "./components/ListImages";
import ListImagesV2 from "./components/ListImagesV2";
import {Helmet} from "react-helmet-async";
import ListPriceHistory from "./components/ListPriceHistory";

const List = ({dispatch,user,listImages, listData,listProducts,theme,rtl}) => {

    const { permalink } = useParams();
    const lm = new ModelList();
    const [isLoaded,setLoaded] = useState(false);
    const [isListOwner,setIsListOwner] = useState(false);
    const [editMode,setEditMode] = useState(false);
    const [uploadError,setUploadError] = useState(false);
    const am = new Authentication();


    useEffect(() => {


        // GET FEATURED List
        lm.getListByPermalink(permalink).then(async response => {
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            setLoaded(true);
        });

    },[]);

    useEffect(() => {
        if(am.isAuthenticated() && listData !==null){
            if(listData.l_owner_id !== user.u_id){
                setIsListOwner(false);
                return;
            }
            setIsListOwner(true);
        }
    },[listData,user]);

    useEffect(()=>{
        if(!editMode)
            return;

        // LOAD THE DATA INTO FORM
        Object.keys(listData).forEach(key => {
            document.querySelectorAll('input[name='+key+']').forEach((el)=>{
                el.value = listData[key];
            });
            document.querySelectorAll('select[name='+key+']').forEach((el)=>{
                el.value = listData[key];
            });
            document.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                el.value = listData[key];
            });
        });

    },[editMode])

    const editList=(e)=>{
        e.preventDefault();
        // USE THE LIST MODEL TO EDIT
        let formData = new FormData(e.currentTarget);
        lm.editList(formData).then(async response => {
            if (response.data.status === 'failed'){
                showNotification(theme,'danger',rtl,'Update List',response.data.msg);
                return;
            }

            showNotification(theme,'success',rtl,'Update List',response.data.msg);
            return;
        });
    }

    const toggleEditMode=()=>{
        if(editMode){
            setEditMode(false);
            return;
        }

        setEditMode(true);
    }

    const priceTrend = ()=>(
        <p className="" style={{fontSize:'1.1rem'}}>
            <b><FontAwesomeIcon icon={faChartBar} style={{fontSize:'1.4rem',color:"green"}}></FontAwesomeIcon><br/>
                    {(listData.price_trend === 'N/A' || listData.price_trend === 'same') &&
                        "N/A"
                    }
                    {listData.price_trend === 'down' &&
                        <>
                            <FontAwesomeIcon transform={"rotate-315"} icon={faArrowDown} style={{color:"red"}}></FontAwesomeIcon>
                            <FontAwesomeIcon transform={"rotate-315"} icon={faArrowDown} style={{color:"red"}}></FontAwesomeIcon>
                        </>
                    }
                    {listData.price_trend === 'up' &&
                        <>
                            <FontAwesomeIcon transform={"rotate-45"} icon={faArrowDown} style={{color:"red"}}></FontAwesomeIcon>
                            <FontAwesomeIcon transform={"rotate-45"} icon={faArrowDown} style={{color:"red"}}></FontAwesomeIcon>
                        </>
                    }
                </b>
        </p>
    );

    return (
        isLoaded &&
            <>
                <Helmet>
                    <title>List: {listData.l_name.substring(0, 130)}</title>
                    <meta name="description" content={"Price, Rating, Where to buy, features, specifications, images for the list: "+listData.l_name}/>
                    <link rel="canonical" href={process.env.REACT_APP_APPLICATION_URL +"/l/"+listData.l_permalink} />
                </Helmet>
                <Container className="Home">
                    <Row>
                        <Col xs={12}>
                            <Card id="listInformation">
                                <CardBody>
                                    {editMode &&
                                    <Row>
                                        <Col xs={12}>
                                            <MultipleImageUpload />
                                            <form className="form--horizontal form" onSubmit={editList}>
                                                <div className="form__form-group row mt-1 mb-2">
                                                    <label htmlFor="l_id" className="col-sm-2 form__form-group-label col-form-label">ID</label>
                                                    <div className="col-sm-4">
                                                        <input type="text" className="form-control-sm" id="l_id" name="l_id"   readOnly/>
                                                    </div>
                                                    <label htmlFor="l_created" className="col-sm-2 form__form-group-label col-form-label">Created</label>
                                                    <div className="col-sm-4">
                                                        <input type="text" className="form-control-sm" id="l_created" name="l_created"   readOnly/>
                                                    </div>
                                                </div>
                                                <div className="form__form-group row mt-1 mb-2">
                                                    <label htmlFor="l_name" className="col-sm-2 form__form-group-label col-form-label">Name</label>
                                                    <div className="col-sm-10">
                                                        <input type="text" className="form-control-sm" id="l_name" name="l_name" />
                                                    </div>
                                                </div>
                                                <div className="form__form-group row mt-1 mb-2">
                                                    <label htmlFor="l_description" className="col-sm-2 form__form-group-label col-form-label">Description</label>
                                                    <div className="col-sm-10">
                                                        <textarea className="form-control-sm" id="l_description" name="l_description" />
                                                    </div>
                                                </div>
                                                <Row className={'form__form-group'}>
                                                    <Col xs={'12'} sm={'12'} className={'text-right'}>
                                                        <hr/>
                                                        <button className="btn-danger btn btn-sm" onClick={toggleEditMode}>Close</button>
                                                        <button className="btn btn-primary btn-sm" type="submit">Update</button>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </Col>
                                    </Row>
                                    }

                                    {!editMode &&
                                    <Row>
                                        <Col xs={4}>
                                            <ListImages/>
                                        </Col>

                                        <ListImagesV2/>
                                        <Col xs={12} sm={8} style={{flexDirection: "column",display: "flex"}}>
                                            <Row className="mb-2">
                                                {(isListOwner) &&
                                                <>
                                                    <Col xs={12} sm={8}>
                                                        <h3 className="page-title"><b>{listData.l_name}</b></h3>
                                                    </Col>
                                                    <Col xs={5} md={3}>
                                                        <button className="btn-secondary btn btn-sm" onClick={toggleEditMode}>Edit <FontAwesomeIcon icon={faEdit} /></button>
                                                    </Col>
                                                </>
                                                }
                                                {(!isListOwner) &&
                                                <Col xs={12} sm={12}>
                                                    <h3 className="page-title mb-0"><b>{listData.l_name}</b></h3>
                                                </Col>
                                                }
                                            </Row>
                                            <Row className="mb-2">
                                                <div className="col-12 d-none d-sm-block">
                                                    <Row>
                                                        <Col xs={4} className="pt-2 text-center themed-border-right themed-border-top">
                                                            <p style={{fontSize:'1.1rem'}}><img src={"/img/ProductPickBoxOnly.png"} alt="Products" style={{maxWidth:"25px",maxHeight:"25px"}} /> <br/><b>{listData.number_of_items}</b></p>
                                                        </Col>
                                                        <Col xs={4} className="pt-2 text-center themed-border-right themed-border-top">
                                                            <p className="" style={{fontSize:'1.1rem'}}><b><FontAwesomeIcon icon={faMoneyBill} style={{fontSize:'1.4rem',color:"green"}}></FontAwesomeIcon> <br/>{listData.total_price}</b></p>
                                                        </Col>
                                                        <Col xs={4} className="pt-2 text-center themed-border-top">
                                                            {priceTrend()}
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="d-block d-sm-none col-12">
                                                    <Row>
                                                        <Col xs={4} className="pt-2 text-center  themed-border-right themed-border-top">
                                                            <p style={{fontSize:'1.1rem'}}><img src={"/img/ProductPickBoxOnly.png"} alt="Products" style={{maxWidth:"25px",maxHeight:"25px"}} /> <br/><b>{listData.number_of_items}</b></p>
                                                        </Col>
                                                        <Col xs={4} className="pt-2 text-center themed-border-right themed-border-top ">
                                                            <p className="" style={{fontSize:'1.1rem'}}><b><FontAwesomeIcon icon={faMoneyBill} style={{fontSize:'1.4rem',color:"green"}}></FontAwesomeIcon> <br/>{listData.total_price}</b></p>
                                                        </Col>
                                                        <Col xs={4} className="pt-2 text-center  themed-border-top">
                                                            <p className="" style={{fontSize:'1.1rem'}}><b><FontAwesomeIcon icon={faChartBar} style={{fontSize:'1.4rem',color:"green"}}></FontAwesomeIcon><br/>
                                                                <FontAwesomeIcon transform={"rotate-45"} icon={faArrowUp} style={{color:"red"}}></FontAwesomeIcon>
                                                                <FontAwesomeIcon transform={"rotate-45"} icon={faArrowUp} style={{color:"red"}}></FontAwesomeIcon>
                                                            </b></p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <p>{listData.l_description}</p>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2" style={{alignItems: "center", marginTop: "auto"}}>
                                                <div className="col-12">
                                                    <Row>
                                                        <Col xs={12} className="pt-2 text-left  themed-border-right themed-border-top">
                                                            <p style={{fontSize:'1.1rem'}}><b>@{listData.u_username}</b></p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>
                                        </Col>


                                    </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="card__title">
                                        <Row>
                                            <Col xs={12} className="mb-1">
                                                <h5 className="bold-text mr-auto">List Products</h5>
                                            </Col>
                                            <Col xs={12} className="mb-1">
                                                <h6>Curated Product List</h6>
                                            </Col>
                                        </Row>


                                    </div>
                                    <ProductsWidget products={listProducts} />
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} sm={6}>
                            <Row>
                                <Col xs={12}>
                                    <Card>
                                        <CardBody>
                                            <ListPriceHistory />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    listData: state.list.listData,
    listProducts:state.list.listProducts,
    listImages:state.list.listImages,
    user : state.user.main
}))(List);