import axios from 'axios';
import Authentication from '../../Models/Authentication';


const Http = axios.create({
    // baseURL: 'https://bcproductsapi.bullishcoder.com',
    baseURL: process.env.REACT_APP_BASE_API_URL,
});

Http.interceptors.request.use(
    function(config) {
        const am = new Authentication();

        if(!am.isAuthenticated())
            return config;
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

Http.interceptors.response.use(
    response => response,
    async (error) => {
        const am = new Authentication();

        const prevRequest = error?.config;
        if ((error?.response?.status === 403 || error?.response?.status === 401) && !prevRequest?.sent) {
            prevRequest.sent = true;
            await am.refreshToken().then(response=>{
                if(!response)
                    window.location.replace("/login");
            });
            prevRequest.headers['Authorization'] = `Bearer ${localStorage.getItem("access_token")}`;
            return Http(prevRequest);
        }
        return Promise.reject(error);
    }
);

export default Http;