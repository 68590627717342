import Http from "../containers/App/Http";
import store from "../containers/App/store";
import {setListImages} from "../redux/actions/Lists/listActions";
import {setUser} from "../redux/actions/userActions";

export default class Authentication {

    authCheck(){
        // CHECK THE AUTHENTICATION TO MAKE SURE A USER IS AUTH BEFORE VIEWING ANYTHING
        return new Promise( (resolve, reject) => {
            if(this.isAuthenticated())
                resolve(true);
            else
                resolve(false);
        });
        // return Http.post("auth/authCheck");
    }

    isAuthenticated(){
        // check the isAuth var in localstorage is no then false
        if(localStorage.getItem("isAuthenticated") !== null){
            let isAuthenticatedVariable = localStorage.getItem("isAuthenticated");
            if(isAuthenticatedVariable === 'N'){
                console.log("not authed");
                return false
            }
        }

        // check the timer if its past then false
        if(localStorage.getItem("expire_at") !== null){
            let expireTime = localStorage.getItem("expire_at");
            if((Date.now()/1000)>expireTime){
                console.log("Time expired");
                return false;
            }else
                return true;
        }

        if(localStorage.getItem("isAuthenticated") !== null){
            let isAuthenticatedVariable = localStorage.getItem("isAuthenticated");
            if(isAuthenticatedVariable === 'Y'){
                console.log("True");
                return true;
            }else
                return false;
        }
        console.log("Nothing Hit");
    }

    login(username, password) {
        const formData = new FormData();
        formData.append('username',username);
        formData.append('password',password);

        return Http
            .post("auth/login", formData,{
                headers:{'content-type':'multipart/formData'}
            })
            .then(response => {
                if (response.data.status === 'failed') {
                    alert(response.data.msg);
                    return false;
                }

                let jwt = response.data.data.jwt;
                let user = response.data.data.user;
                let refreshToken = response.data.data.refreshToken;
                let expire_at = response.data.data.expireAt;

                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("access_token", jwt);
                localStorage.setItem("refresh_token", refreshToken);
                localStorage.setItem("expire_at", expire_at);
                localStorage.setItem("isAuthenticated", "Y");

                // SET Http TO USE THE TOKEN
                Http.defaults.headers.common = {
                    'Authorization': `Bearer ${localStorage.access_token}`
                };
                //GO TO DASHBOARD OR REDIRECT LINK

                return response;

            });
    }

    loginUsingFormData(formData) {

        return Http
            .post("auth/login", formData,{
                headers:{'content-type':'multipart/formData'}
            })
            .then(response => {
                if (response.data.status === 'failed') {
                    // alert(response.data.msg);
                    return response;
                }

                let jwt = response.data.data.jwt;
                let user = response.data.data.user;
                let refreshToken = response.data.data.refreshToken;
                let expire_at = response.data.data.expireAt;

                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("access_token", jwt);
                localStorage.setItem("refresh_token", refreshToken);
                localStorage.setItem("expire_at", expire_at);
                localStorage.setItem("isAuthenticated", "Y");

                // SET Http TO USE THE TOKEN
                Http.defaults.headers.common = {
                    'Authorization': `Bearer ${localStorage.access_token}`
                };
                //GO TO DASHBOARD OR REDIRECT LINK

                return response;

            });
    }

    refreshToken(){
        // CHECK FOR A REFRESH TOKEN IN THE LOCAL STORAGE
        const formData = new FormData();
        formData.append('refresh_token', localStorage.getItem("refresh_token"));
        return Http
            .post("auth/refreshToken", formData,{
                headers:{
                    'content-type':'multipart/formData'
                }
            })
            .then(response => {
                if (response.data.status === 'failed') {
                    return false;
                }

                // let jwt = response.data.data.jwt;
                // let refreshToken = response.data.data.refreshToken;
                // let expire_at = response.data.data.expireAt;
                //
                // localStorage.setItem("access_token", jwt);
                // localStorage.setItem("refresh_token", refreshToken);
                // localStorage.setItem("expire_at", expire_at);
                // localStorage.setItem("isAuthenticated", "Y");
                //
                // // TODO: ADD THE USER OBJ TO REDUX
                //
                //
                // // SET Http TO USE THE TOKEN
                // Http.defaults.headers.common = {
                //     'Authorization': `Bearer ${localStorage.access_token}`
                // };
                // //GO TO DASHBOARD OR REDIRECT LINK

                return true;
            });
    }

    logout() {

        //localStorage.removeItem("user");
        return Http
            .post("auth/logout")
            .then(response => {
                if (response.data.status === 'failed') {
                    alert('failed to logout');
                    return false;
                }
                localStorage.removeItem("isAuthenticated");
                localStorage.removeItem('access_token');
                localStorage.removeItem('expire_at');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('user');
                store.dispatch(setUser(null));
                
                return true
            });


    }

}