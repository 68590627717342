import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import {connect} from "react-redux";
import ProductsTable from "./components/ProductsTable";
import {showNotification} from "../Notification";
import LoadingIcon from 'mdi-react/LoadingIcon';

const ProductsWidget = ({ dispatch, products,rtl,theme,className }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    // if(productData.vt_ids == null || productData.vt_ids.length === 0 || typeof productData.vt_ids === "undefined")
    //     return;

    useEffect(() => {
        // dispatch(showVenueMenu(true));
        setIsLoaded(true);
    },[]);

    return (
        <div className={className}>
            {!isLoaded &&
                <div className="panel__refresh"><LoadingIcon/></div>
            }
            {isLoaded &&
                <ProductsTable products={products}/>
            }
        </div>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    loadedProducts:state.products.loadedProducts,
    // showVenueMenu:state.venue.showVenueMenu
}))(ProductsWidget);