import React, {useEffect} from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Register from '../Register';
import Product from "../Product";
import ProductEdit from "../ProductEdit";
import Category from "../Category";
import Home from "../Home";
import ProtectedRoute from './ProtectedRoute';
import Lists from '../Lists';
import List from "../List";
import {connect} from "react-redux";
import ReactGA from "react-ga4";
import Search from "../Search";
import {Card, CardBody, Container} from "reactstrap";
import SearchCard from "../../shared/components/Search";

const Pages = () => (
  <Switch>
    {/*<Route path="/pages/one" component={ExamplePageOne} />*/}
    {/*<Route path="/pages/two" component={ExamplePageTwo} />*/}
  </Switch>
);

const wrappedRoutes = () => (
    <div>
        <Layout />
        <div className="container__wrap">

            <Route exact path="/" component={Home} />
            <Route path="/pages" component={Pages} />
            <Route path="/lists" component={Lists} />
            <Route path="/l/:permalink" component={List} />
            <Route exact path="/product/:plink" component={Product} />
            <Route exact path="/c/:plink" component={Category} />
            <Route exact path="/s" component={Search} />
            <ProtectedRoute path="/edit/:productId" component={ProductEdit} />
        </div>
    </div>
);

const protectedRoutes = () => (
    <div>
        <Layout />
        <div className="container__wrap">
            <Route exact path="/" component={Home} />
            <Route path="/pages" component={Pages} />
            <Route exact path="/product/:plink" component={Product} />
            <Route exact path="/c/:plink" component={Category} />
            <ProtectedRoute path="/edit/:productId" component={ProductEdit} />
        </div>
    </div>
);

const Router = ({dispatch, user,rtl,theme}) => {

    const location =  useLocation();
    ReactGA.initialize("G-RQBRGHV2R7");

    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: location.pathname });
        console.log("route changed . "+location.pathname);
    },[location.pathname]);

    useEffect(()=>{

    },[user])

    return (
        <MainWrapper>
            <main>
                <Switch>
                    <Route exact path="/login" component={LogIn} />
                    <Route exact path="/register" component={Register} />
                    <Route path="/" component={wrappedRoutes} />
                </Switch>
            </main>
        </MainWrapper>
    );
}

export default connect(state => ({
    user : state.user.main,
    rtl: state.rtl,
    theme: state.theme
}))(Router);