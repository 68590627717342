import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import {Card, CardBody, Col, Row} from 'reactstrap';
import ModelProduct from '../../../../Models/Product';
import Panel from "../../../../shared/components/Panel";
import {showNotification} from "../../../../shared/components/Notification";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faCheck,faCheckCircle} from '@fortawesome/fontawesome-free-solid';

const PositiveAndNegativeFeatures = ({dispatch,features,theme,rtl}) => {

    const vm = new ModelProduct();
    const [positiveFeatures,setPositiveFeatures] = useState(false);
    const [negativeFeatures,setNegativeFeatures] = useState(false);
    const [generalFeatures,setGeneralFeatures] = useState(false);

    useEffect(() =>{
        let pos=[];
        let neg=[];
        let gen=[];

        if(features!== false){
            features.forEach((feature)=>{
                if(feature.pf_type==='P')
                    pos.push(feature.pf_description);
                if(feature.pf_type==='N')
                    neg.push(feature.pf_description);
                if(feature.pf_type==='G')
                    gen.push(feature.pf_description);
            });
            if(pos.length>0)
                setPositiveFeatures(pos);
            if(neg.length>0)
                setNegativeFeatures(neg);
            if(gen.length>0)
                setGeneralFeatures(gen);
        }
    },[features]);

    return (
        <Row>
            <Panel xl={12} lg={12} md={12} title={"Product Features"} refreshFunction="">
                <ul className="p-0 fa-ul" style={{listStyle:"none"}}>
                    {generalFeatures !== false && generalFeatures.map((feature, index) => (
                        <li key={index} className="pb-2">
                            <p style={{fontSize:'1rem'}}><FontAwesomeIcon className="fa-li" icon={faCheckCircle} style={{color:"#8a2be2"}}/> {feature}</p>
                        </li>
                    ))}
                </ul>
            </Panel>
            {/*<Col xs={12} sm={6}>*/}
            {/*    <Card>*/}
            {/*        <CardBody style={{backgroundColor:"#20b2aa"}}>*/}
            {/*            <div className="card__title">*/}
            {/*                <h5 className="bold-text">Positive Features</h5>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <ul className="fa-ul p-0" style={{listStyle:"none"}}>*/}
            {/*                    {positiveFeatures !== false && positiveFeatures.map((feature, index) => (*/}
            {/*                        <li key={index}>*/}
            {/*                            <FontAwesomeIcon className={"fa-li"} icon={faCheckCircle} style={{color:"#8a2be2"}}/><p>{feature}</p>*/}
            {/*                        </li>*/}
            {/*                    ))}*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </CardBody>*/}
            {/*    </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={12} sm={6}>*/}
            {/*    <Card>*/}
            {/*        <CardBody style={{backgroundColor:"#f08080"}} >*/}
            {/*            <div className="card__title">*/}
            {/*                <h5 className="bold-text">Negative Features</h5>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <ul className="fa-ul p-0" style={{listStyle:"none"}}>*/}
            {/*                    {negativeFeatures !== false && negativeFeatures.map((feature, index) => (*/}
            {/*                        <li key={index}>*/}
            {/*                            <FontAwesomeIcon className={"fa-li"} icon={faTimes} style={{color:"#ff0000"}}/><p>{feature}</p>*/}
            {/*                        </li>*/}
            {/*                    ))}*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </CardBody>*/}
            {/*    </Card>*/}
            {/*</Col>*/}
        </Row>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    features:state.product.features,
    // showVenueMenu:state.venue.showVenueMenu
}))(PositiveAndNegativeFeatures);