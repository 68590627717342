import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarUnauthenticated from './TopbarUnauthenticated';
import Authentication from "../../../Models/Authentication";
import {connect} from "react-redux";
import Search from "../../../shared/components/Search";
import SearchCard from "../../../shared/components/Search";

const Topbar = ({ user,changeMobileSidebarVisibility, changeSidebarVisibility }) => {

    const [authed,setIsAuthed] = useState(false);
    const auth = new Authentication();


    useEffect(()=>{
        if(auth.isAuthenticated())
            setIsAuthed(true);
        else
            setIsAuthed(false);
    },[user]);

    return (
        <>
            <div className="topbar">
                <div className="topbar__wrapper">
                    <div className="topbar__left">
                        <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} changeSidebarVisibility={changeSidebarVisibility} />
                        <Link className="topbar__logo" to="/" />
                        <Search classes="pl-5 d-none d-sm-flex" />
                    </div>
                    <div className="topbar__right">
                        {/* ADD THE LOGIN AND REGISTER BUTTONS HERE*/}
                        {authed &&
                            <TopbarProfile />
                        }
                        {!authed &&
                            <TopbarUnauthenticated />
                        }
                    </div>
                </div>
            </div>
            <div className="mobile__search__container d-block d-sm-none">
                <SearchCard classes="p-1"/>
            </div>

        </>
    )};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default connect(state => ({
    user : state.user.main
}))(Topbar);
