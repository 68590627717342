/* eslint-disable */
import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import RegisterForm from './components/RegisterForm';
import Registration from "../../Models/Registration";
import { useHistory } from "react-router-dom";
import {
    setUser
} from "../../redux/actions/userActions";
import {connect} from "react-redux";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import {Field} from "react-final-form";
import PasswordField from "../../shared/components/form/Password";
import Alert from "../../shared/components/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt} from "@fortawesome/free-solid-svg-icons";


const Register = ({dispatch,user}) => {

    const history = useHistory();
    const am = new Registration();
    const [registrationError,setRegistrationError] = useState(null);
    const [RegistrationSuccess,setRegistrationSuccess] = useState(null);

    const handleRegister = (e) => {
        e.preventDefault();
        // this.setState({
        //     message: "",
        //     loading: true
        // });

        // THE REACT FINAL FORM DATA IS IN THE PASSED OBJECT

        let form_data = new FormData(document.getElementById('registerNewUserForm'));

        am.registerUser(form_data).then(
            (response) => {
                if(response.data.status==='failed') {
                    setRegistrationError(response.data.msg);
                    return;
                }
                setRegistrationError(null);
                setRegistrationSuccess(response.data.msg);

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );

    };

    const goToLoginPage=()=>{
        history.push("/login");
    }

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">Product Analytics <span className="account__logo">Register</span></h3>
                    <h4 className="account__subhead subhead">Start easily</h4>
                </div>
              {registrationError !== null &&
                  <Alert id="registrationErrorContainer" className="alert alert-danger">
                    <p>{registrationError}</p>
                  </Alert>
              }
              {RegistrationSuccess !== null &&
                  <Alert id="registrationSuccessContainer" className="alert alert-success">
                    <p>{RegistrationSuccess}</p>
                      <button type="button" className="btn btn-primary btn-sm btn-block" onClick={goToLoginPage}>Go to Login</button>
                  </Alert>
              }

              <form className="form" id="registerNewUserForm" onSubmit={handleRegister}>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Username</span>
                      <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                              {/*<AccountOutlineIcon />*/}
                              <FontAwesomeIcon icon={faAt}></FontAwesomeIcon>
                          </div>
                          <input name="u_username" type="text" placeholder="Username"/>
                      </div>
                  </div>
                  <div className="form__form-group">
                      <span className="form__form-group-label">First Name</span>
                      <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                              <AccountOutlineIcon />
                          </div>
                          <input name="u_first" component="input" type="text" placeholder="First Name"/>
                      </div>
                  </div>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Last Name</span>
                      <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                              <AccountOutlineIcon />
                          </div>
                          <input name="u_last" component="input" type="text" placeholder="Last Name"/>
                      </div>
                  </div>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Email</span>
                      <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                              <AccountOutlineIcon />
                          </div>
                          <input name="u_email" type="email" placeholder="Email"/>
                      </div>
                  </div>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Password</span>
                      <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                              <AccountOutlineIcon />
                          </div>
                          <input
                              name="u_password"
                              type="text"
                              placeholder="Password"
                              className="input-without-border-radius"
                          />
                      </div>
                  </div>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Retype Password</span>
                      <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                              <AccountOutlineIcon />
                          </div>
                          <input
                              name="con_password"
                              type="text"
                              placeholder="Password"
                              className="input-without-border-radius"
                          />
                      </div>
                  </div>
                  <button className="btn btn-primary account__btn account__btn--small" type="submit">Register</button>
              </form>
          </div>
        </div>
      </div>
    );

};

export default connect(state => ({
    user : state.user.main
}))(Register);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
