import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useLocation, useParams} from 'react-router-dom';
import ModelCategory from '../../Models/Category';
import ModelProducts from '../../Models/Products';
import ProductsTable from "../../shared/components/ProductsTable";
import Panel from "../../shared/components/Panel";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/fontawesome-free-solid";
import LoadingIcon from "mdi-react/LoadingIcon";
import { useHistory } from "react-router-dom";
import SearchModel from "../../Models/Search";
import {getUrlVars } from "../../utils/helpers";
import ProductsTableV2 from "../../shared/components/ProductsTableV2";

const Search = ({dispatch,productResults,listsResults}) => {

    const history = useHistory();
    const sm = new SearchModel();
    const [isLoaded,setLoaded] = useState(false);
    const [term,setTerm] = useState('');
    const location =  useLocation();

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(() => {
        // GET THE GET VAR FOR SEARCH
        let vars = getUrlVars();
        if(typeof vars !== "undefined" && vars !== null){
            if(typeof vars['term'] !== "undefined")
                setTerm(decodeURI(vars['term']));
            else
                redirect("/");
        }else
            redirect("/");

        sm.globalSearch(decodeURI(vars['term'])).then(async response=>{
            setLoaded(true);
        });
    },[location.search]);

    const columns =[
        {
            width: 50,
            Header: "Image",
            Cell: ({ cell }) => {
                return (
                    <>
                        {cell.row.original.p_featured_image===null &&
                        <img style={{borderRadius:'10px', maxWidth:'100px'}} src="https://picsum.photos/300/200" alt="Featured Image" />
                        }
                        {cell.row.original.p_featured_image!==null &&
                        <img style={{borderRadius:'10px', maxWidth:'100px'}} src={process.env.REACT_APP_BASE_API_URL +"/assets/images/"+cell.row.original.p_featured_image} alt="" />
                        }

                    </>
                )
            }
        },{
            width: 300,
            Header: "Name",
            Cell: ({ cell }) => {
                return (
                    <p style={{cursor:'pointer'}} className={ "m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/product/'+cell.row.original.p_id)}}>
                        {cell.row.original.p_name}
                    </p>
                )
            }
        },
        {
            Header: 'Model',
            accessor: 'p_model',
            width: 150
        }
    ];

    return (
        isLoaded &&
            <Container className="dashboard">
                <Row>
                    {/*GIVE THIS IS A BACKGROUND IMAGE WITH ROUNDED CORNERS CENTER AND FILLED*/}
                    <Col xs={12} md={12} className={'pt-2 pb-2 text-center'}>
                        <Card>
                            <CardBody>
                                <h1>Term: {term}</h1>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12} className={'pt-2 pb-2'}>
                        <Card>
                            <CardBody>
                                {productResults===null &&
                                    <div className="panel__refresh"><LoadingIcon/></div>
                                }
                                {productResults.length === 0 &&
                                    <h2>No results found</h2>
                                }
                                {productResults.length > 0 &&
                                    <ProductsTableV2 allowSearch={false} columnsStructure={columns}  productsData={productResults}/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        </Container>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    productResults:state.search.productResults,
    listsResults:state.search.listsResults
}))(Search);