import React,{ useEffect,useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {connect} from "react-redux";
import ModelCategories from '../../../Models/Categories';
import { faUserSlash,faPencilAlt,faEye,faTimes } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../shared/components/Notification";
import { Link } from 'react-router-dom';

const FeaturedCategories = ({ dispatch,theme,rtl}) => {

    const history = useHistory();
    const [isLoaded,setLoaded] = useState(false);
    const [categories,setCategories] = useState(false);
    const cm = new ModelCategories();

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(() =>{
        let formData = new FormData();
        formData.append("limit","12");
        formData.append("hasPermalink","Y");
        formData.append("featured","Y");
        // showNotification(theme,'success',rtl,'Update Product',"hello");
        cm.getAllCategories(formData).then(async (response)=>{
            await setCategories(response.data.data);
            setLoaded(true);
        });
    },[]);

    return (
        isLoaded &&
            <>
                {categories!==false && categories.map((c,i)=>(
                    <Card className="col-6 col-md-2" key={i}>
                        <CardBody className="text-center">
                            <Link to={"/c/"+c.pc_permalink}>
                                {c.i_filename!==null &&
                                    <div className="text-center mb-3">
                                        <img
                                            className="img-fluid"
                                            src={process.env.REACT_APP_BASE_API_URL + "/assets/category_images/"+ c.i_filename}
                                            style={{borderRadius:"10px"}}
                                        />
                                    </div>
                                }
                                <h4>{c.pc_name}</h4>
                            </Link>
                        </CardBody>
                    </Card>
                ))}
            </>

    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme
}))(FeaturedCategories);
