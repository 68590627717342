import React, { useEffect,useState,useRef  } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Row,
    Col,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    CardBody,
    Card
} from 'reactstrap';
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/fontawesome-free-solid";
import {faAngleDoubleUp, faArrowDown, faArrowUp, faEdit, faShare, faShareAlt} from "@fortawesome/free-solid-svg-icons";

const ListItem = ({dispatch,rtl,theme,list,owner}) => {

    const history = useHistory();
    const [isOwner, setIsOwner] = useState(false);

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(() => {
        if(owner ===true)
            setIsOwner(true);


    },[]);

    return (
        <Card className="listItemContainer">
            <CardBody className="text-center">
                <Row>
                    <Col xs={12}>
                        {list.i_filename!==null &&
                            <img src={process.env.REACT_APP_BASE_API_URL +"/assets/list_images/"+list.i_filename} alt="Featured Image"
                                 style={{borderRadius:"10px", height:"200px",width:"100%", objectFit: 'cover', objectPosition: 'center center'}}
                            />
                        }
                        {list.i_filename===null &&
                                <img src="https://picsum.photos/300/200" alt="Featured Image"
                                     style={{borderRadius:"10px", height:"200px",width:"100%", objectFit: 'cover', objectPosition: 'center center'}}
                                />
                        }
                    </Col>
                    <Col xs={12} className="mt-3">
                        <div className="listCardHeader row">
                            <div className="listItemCardTitle col-9 text-left">
                                <h4><a style={{cursor:'pointer'}} onClick={e => {e.preventDefault(); redirect('/l/'+list.l_permalink)}}><b>{list.l_name}</b></a></h4>
                            </div>
                            <div className="col-3 text-right">
                                <h4>
                                    <a style={{cursor:'pointer'}} onClick={e => {e.preventDefault(); redirect('/l/'+list.l_permalink)}}>
                                        <img src={"/img/ProductPickBoxOnly.png"} alt="Products" style={{maxWidth:"25px",maxHeight:"25px"}} /> {list.number_of_items}
                                    </a>
                                </h4>
                            </div>
                            <hr/>
                        </div>
                        {/*<Link to={"/c/"+c.pc_permalink}><h4>{c.pc_name}</h4></Link>*/}
                        <Row>
                            <Col xs="12" className="pb-1 pt-1 text-left" style={{height:'5rem', overflow:'hidden'}}>
                                <p>{list.l_description.substring(0, 130) + (list.l_description.length > 130 ? '...' : '')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="text-left">
                                <p><b>Total Price: {list.total_price}</b></p>
                            </Col>
                            <Col xs="6" className="text-right">
                                {(list.price_trend === 'N/A' || list.price_trend === 'same') &&
                                    <p><b>Price Trend: N/A</b></p>
                                }
                                {list.price_trend === 'down' &&
                                    <p><b>Price Trend: <FontAwesomeIcon transform={"rotate-315"} icon={faArrowDown} style={{color:"red",fontSize:'1.1rem'}}></FontAwesomeIcon></b></p>
                                }
                                {list.price_trend === 'up' &&
                                    <p><b>Price Trend: <FontAwesomeIcon transform={"rotate-45"} icon={faArrowUp} style={{color:"red",fontSize:'1.1rem'}}></FontAwesomeIcon></b></p>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="border-top mt-2 pt-2">
                    <Col xs="12" sm="6" className="text-left">
                        <h5 style={{fontWeight:"700",fontSize:'1rem'}}><span style={{fontSize:'1.3rem'}}>@</span>{list.u_username}</h5>
                    </Col>
                    {/*<Col xs="12" sm="6" className="text-right">*/}

                    {/*    <a className={ "btn btn-sm btn-dark m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/l/'+list.l_permalink)}}>*/}
                    {/*        <FontAwesomeIcon icon={faEye} style={{margin:0}}/>*/}
                    {/*    </a>*/}
                    {/*    <a className={ "btn btn-sm btn-dark m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/l/'+list.l_permalink)}}>*/}
                    {/*        <FontAwesomeIcon icon={faShareAlt} style={{margin:0}}/>*/}
                    {/*    </a>*/}
                    {/*</Col>*/}
                </Row>

            </CardBody>
        </Card>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
}))(ListItem);