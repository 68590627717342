import React, { useEffect,useState,useRef  } from 'react';
import { connect } from 'react-redux';
import ModelLists from '../../../Models/Lists/Lists';
import { Button,Row,Col, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import Alert from "../../../shared/components/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Http from '../../../../src/containers/App/Http';
// import {showNotification} from "../../components/Notification";
import Cleave from "cleave.js/react";
import Select from 'react-select';

import AsyncSelect from 'react-select/async';
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import Authentication from "../../../Models/Authentication";
import ModalLogin from "../Login/ModalLogin";
// import {setActiveVenueId} from "../../../redux/actions/userActions";

const AddProductToList = ({dispatch,user,productIds,listsByUser, isOpen,toggle,rtl,theme}) => {
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);

    const lsm = new ModelLists();
    const [authed,setIsAuthed] = useState(false);
    const auth = new Authentication();


    useEffect(() => {
        if(auth.isAuthenticated()){
            setIsAuthed(true);
            lsm.getAllListsByUser().then(async response => {
                if (response.data.status === 'failed') {
                    alert('failed');
                    return false;
                }
            });
        }else
            setIsAuthed(false);

    },[user]);

    const handleSubmit= () =>{
        document.querySelector("#formAddToList button[type=submit]").click();
    };

    const AddToList=async (form)=>{
        form.preventDefault();
        setProcessing(true);

        let formData = new FormData(document.getElementById('formAddToList'));
        formData.append('productId',productIds[0]);
        Http.post("/l/addProductToList",formData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            // RELOAD THE USER OBJECT INTO REDUX
            // um.setUserRedux(response.data.data,(response.data.data.hasOwnProperty('roles')) ? response.data.data.roles:null);
            // if(response.data.data.hasOwnProperty('roles')){
            //     await dispatch(setActiveVenueId(response.data.data.roles[0].v_id));
            // }

            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);
        });

    };

    return (

            <>
                {!authed &&
                    <ModalLogin toggle={toggle} isOpen={isOpen}/>
                }
                {authed &&
                    <Modal isOpen={isOpen} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} id="ModalRequestAccess" modalClassName={`${rtl.direction}-support`} size="md">
                    <div className="modal__header">
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            aria-label="modal__close-btn"
                            type="button"
                            onClick={toggle}
                        />
                        <h4 className="text-modal  modal__title">Add to List</h4>
                    </div>

                    <div className="modal__body">

                        <form id="formAddToList" className={"form--horizontal form"} onSubmit={AddToList}>
                            <button type="submit" style={{'display': 'none'}}>Submit</button>

                            <Col xs={'12'}>
                                {errorMsg !==false &&
                                    <Alert color="danger">
                                        {errorMsg}
                                    </Alert>
                                }
                                {successMsg !== false &&
                                    <Alert className="alert alert-success">
                                        <p>{successMsg}</p>
                                    </Alert>
                                }
                            </Col>

                            <div className="col-12 form__form-group">
                                <Alert color="info" className="p-2">
                                    <p><span className="bold-text">Information: </span>
                                        Use the dropdown to add the product to one of your list or create a new list
                                    </p>
                                </Alert>
                            </div>
                            <div className="form__form-group mt-1">
                                <label htmlFor="u_default_venue_id" className="col-sm-12 form__form-group-label col-form-label">My Lists</label>
                                <div className="col-sm-12">
                                    <select className={"form-control-sm col-12 form-control"} name='l_id'>
                                        {typeof listsByUser !=="undefined" && listsByUser!== null && listsByUser.map((list,i)=>(
                                            <option key={i} value={list.l_id}>{list.l_name} | {list.number_of_items}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit} disabled={processing}>Add</button>
                            </div>

                        </form>

                    </div>

                </Modal>
                }

            </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    listsByUser: state.lists.listsByUser,
    user: state.user.main
}))(AddProductToList);