import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {
    setProduct,
    setFeatures,
    setSpecifications,
    setPriceTrackerData,
    setRatingTrackerData,
    setLinks,
    setCategories,
    setImages,
    setPriceExtended,
    setRatingExtended,
    setCategoriesExtended,
    setProductPosts
} from "../redux/actions/productActions";

export default class Product{

    getProductData(productId, priceHistoryRange, ratingHistoryRange) {
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('productId', productId);
        formData.append('priceHistoryRange', priceHistoryRange);
        formData.append('ratingHistoryRange', ratingHistoryRange);

        // Define all your asynchronous operations
        let p = Http.post("/p/getProduct", formData);
        let links = Http.post("/p/getLinks", formData);
        let images = Http.post("/p/getImages", formData);
        let posts = Http.post("/p/getPostAboutProduct", formData);
        let specifications = Http.post("/p/getSpecifications", formData);
        let features = Http.post("/p/getFeatures", formData);

        // Use Promise.all to handle all requests
        return Promise.all([p, links, images, posts, specifications, features])
            .then(async ([productResponse, linksResponse, imagesResponse, postsResponse, specificationsResponse, featuresResponse]) => {
                // Handle each response, dispatch actions, and check for failures
                if (productResponse.data.status !== 'failed') {
                    await store.dispatch(setProduct(productResponse.data.data[0][0]));
                    await store.dispatch(setPriceExtended(productResponse.data.data[2]));
                    await store.dispatch(setRatingExtended(productResponse.data.data[3]));
                }

                if (linksResponse.data.status !== 'failed') {
                    await store.dispatch(setLinks(linksResponse.data.data));
                }

                if (imagesResponse.data.status !== 'failed') {
                    await store.dispatch(setImages(imagesResponse.data.data));
                }

                if (postsResponse.data.status !== 'failed') {
                    await store.dispatch(setProductPosts(postsResponse.data.data));
                }

                if (specificationsResponse.data.status !== 'failed') {
                    await store.dispatch(setSpecifications(specificationsResponse.data.data));
                }

                if (featuresResponse.data.status !== 'failed') {
                    await store.dispatch(setFeatures(featuresResponse.data.data));
                }

                // Return all responses as an object
                return {
                    product: productResponse.data,
                    links: linksResponse.data,
                    images: imagesResponse.data,
                    posts: postsResponse.data,
                    specifications: specificationsResponse.data,
                    features: featuresResponse.data
                };
            });
    }

    getPriceHistory(productId, priceHistoryRange) {
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('productId', productId);
        formData.append('priceHistoryRange', priceHistoryRange);

        // Define all your asynchronous operations
        let priceHistory = Http.post("/p/getPriceHistory", formData);

        // Use Promise.all to handle all requests
        return Promise.all([priceHistory])
            .then(async ([priceHistoryResponse]) => {
                if (priceHistoryResponse.data.status !== 'failed')
                    await store.dispatch(setPriceTrackerData(priceHistoryResponse.data.data));

                return priceHistoryResponse;
            });
    }

    getRatingHistory(productId, ratingHistoryRange) {
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('productId', productId);
        formData.append('ratingHistoryRange', ratingHistoryRange);

        // Define all your asynchronous operations
        let ratingHistory = Http.post("/p/getRatingHistory", formData);

        // Use Promise.all to handle all requests
        return Promise.all([ratingHistory])
            .then(async ([ratingHistoryResponse]) => {
                if (ratingHistoryResponse.data.status !== 'failed')
                    await store.dispatch(setRatingTrackerData(ratingHistoryResponse.data.data));


                return ratingHistoryResponse;
            });
    }

    getProductDataForEditor(productId){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('productId',productId);

        return Http.post("/p/getProductForEditor",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setProduct(response.data.data[0][0]));
            await store.dispatch(setLinks(response.data.data[1]));
            await store.dispatch(setSpecifications(response.data.data[2]));
            await store.dispatch(setFeatures(response.data.data[3]));
            await store.dispatch(setPriceTrackerData(response.data.data[4]));
            await store.dispatch(setRatingTrackerData(response.data.data[5]));
            await store.dispatch(setImages(response.data.data[13]));
            return response;
        });
    };

    addSpec(formData){

        return Http.post("/p/addSpecification",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    addFeature(formData){

        return Http.post("/p/addFeature",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    // updateSpec(productId){
    //     // FETCH THE VENUE INFO
    //     const formData = new FormData();
    //     formData.append('productId',productId);
    //
    //     return Http.post("/p/getProduct",formData).then(async response => {
    //         if (response.data.status === 'failed')
    //             return response;
    //
    //         await store.dispatch(setProduct(response.data.data[0][0]));
    //         await store.dispatch(setFeatures(response.data.data[1]));
    //         await store.dispatch(setSpecifications(response.data.data[2]));
    //         return response;
    //     });
    // };
    //
    // updateFeature(productId){
    //     // FETCH THE VENUE INFO
    //     const formData = new FormData();
    //     formData.append('productId',productId);
    //
    //     return Http.post("/p/getProduct",formData).then(async response => {
    //         if (response.data.status === 'failed')
    //             return response;
    //
    //         await store.dispatch(setProduct(response.data.data[0][0]));
    //         await store.dispatch(setFeatures(response.data.data[1]));
    //         await store.dispatch(setSpecifications(response.data.data[2]));
    //         return response;
    //     });
    // };

    updateProduct(formData){

        return Http.post("/p/updateProduct",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    generateArticle(){
        return Http.post("/ag/generate").then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    setFeaturedImage(formData){
        return Http.post(process.env.REACT_APP_BASE_API_URL +"/p/setFeaturedImage",formData).then(response => {
            return response;
        });
    }
}