import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelLists from '../../Models/Lists/Lists';
import FeaturedCategories from "../../shared/components/FeaturedCategories";
import MyLists from "../../shared/components/Lists/MyLists";
import FeaturedLists from "../../shared/components/Lists/FeaturedLists";
import LatestLists from "../../shared/components/Lists/LatestLists";
import Authentication from "../../Models/Authentication";
import {connect} from "react-redux";

const Lists = ({user}) => {

    const { plink } = useParams();
    const lsm = new ModelLists();
    const auth = new Authentication();
    const [authed,setIsAuthed] = useState(false);

    const [isLoaded,setLoaded] = useState(false);

    useEffect(() => {
        // GET USERS PERSONAL LISTS - IF LOGGED IN
        if(auth.isAuthenticated())
            setIsAuthed(true);
        else
            setIsAuthed(false);
        // GET FEATURED LISTS
        lsm.getAllListsForListsPage().then(async response => {
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }
        });

    },[user]);

    return (
        <Container className="Home">
            {authed &&
                <>
                    <Row>
                        <Col md={12}>
                            <h3 className="page-title">My Lists</h3>
                        </Col>
                    </Row>
                    <MyLists/>
                </>
            }


            <Row>
                <Col md={12}>
                    <h3 className="page-title">Featured</h3>
                </Col>
            </Row>
            <FeaturedLists/>

            <Row>
                <Col md={12}>
                    <h3 className="page-title">Latest</h3>
                </Col>
            </Row>
            <LatestLists/>
        </Container>
    );
}

export default connect(state => ({
    user : state.user.main
}))(Lists);