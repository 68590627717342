// export const getOidcStorageKey = () => {
//   const authSettings = JSON.parse(localStorage.getItem('authSettings'));
//   if (authSettings) {
//     return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
//   }
//   return null;
// };
//
// export const getOidcInfo = () => {
//   const key = getOidcStorageKey();
//   if (key) {
//     const oidc = JSON.parse(localStorage.getItem(key));
//     return oidc;
//   }
//   return null;
// };
//
// export const getToken = () => {
//   const oidc = getOidcInfo();
//   if (oidc) {
//     return oidc.id_token;
//   }
//   return null;
// };

// Read a page's GET URL variables and return them as an associative array.
export const getUrlVars = ()=>{
    let vars = [], hash;
    let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(let i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
};

export const getDateBeforeXDays= (X) => {
    let date = new Date();
    date.setDate(date.getDate() - X);

    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);  // Months are 0-based in JavaScript
    let day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
}