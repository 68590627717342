import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Card,
    CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductReviewArticlesTable from "./components/ProductReviewArticlesTable";
import LoadingIcon from "mdi-react/LoadingIcon";
import ProductsTableV2 from "../../../../shared/components/ProductsTableV2";

const ProductReviewArticles = ({dispatch, data, productPosts, specifications, rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);

    useEffect(()=>{
        setLoaded(false);
        if(typeof productPosts !== 'undefined' || productPosts!==null){
            setLoaded(true);
        }
    },[productPosts]);

    return (

            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">Product Review Articles</h5>
                        <h5 className="subHead">In this section we list product reviews that Product Pickr has identified</h5>
                    </div>
                    {productPosts===null &&
                    <div className="panel__refresh"><LoadingIcon/></div>
                    }
                    {productPosts.length === 0 &&
                        <div className="text-center">
                            <hr/>
                            <h4>No Articles Found</h4>
                        </div>
                    }
                    {productPosts.length > 0 &&
                    <div>
                        <ProductReviewArticlesTable/>
                    </div>
                    }

                </CardBody>
            </Card>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    productPosts:state.product.productPosts,
    // users:state.users.users,
}))(ProductReviewArticles);