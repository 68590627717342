import React,{useState} from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faStarHalf} from '@fortawesome/fontawesome-free-solid';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons'
import {Badge} from "reactstrap";
import CreateList from "../../../shared/components/CreateList";

const SidebarContent = ({ onClick, changeToDark, changeToLight }) => {

    const [viewCreateList,setViewCreateList]= useState(false);

    const handleHideSidebar = () => {
        onClick();
    };

    const openCreateList=() => {
        setViewCreateList(true);
    };

    const toggle=() => {
        setViewCreateList(prevState=>!prevState);
    };

    return (
        <div className="sidebar__content">
          <ul className="sidebar__block">
              <SidebarLink title="Home" icon="home" route="/" onClick={handleHideSidebar} />
              <SidebarLink title="My Lists" icon="heart" route="/lists" onClick={handleHideSidebar} />
              {/*<SidebarLink title="Dashboard" icon="exit" route="/dashboard" onClick={handleHideSidebar} />*/}
            {/*<SidebarLink title="Products" icon="exit" route="/products" onClick={handleHideSidebar} />*/}
          </ul>
          <ul className="sidebar__block">
              <li className="sidebar__link">
                  <FontAwesomeIcon icon={faPlus} />
                  <p className="sidebar__link-title" onClick={openCreateList}>
                       Create List
                  </p>
              </li>
            <SidebarCategory title="Layout" icon="layers">
              <button type="button" className="sidebar__link" onClick={changeToLight}>
                <p className="sidebar__link-title">Light Theme</p>
              </button>
              <button type="button" className="sidebar__link" onClick={changeToDark}>
                <p className="sidebar__link-title">Dark Theme</p>
              </button>
            </SidebarCategory>
          </ul>
          {/*<ul className="sidebar__block">*/}
            {/*<SidebarCategory title="Example Pages" icon="diamond">*/}
              {/*<SidebarLink title="Page one" route="/pages/one" onClick={handleHideSidebar} />*/}
              {/*<SidebarLink title="Page two" route="/pages/two" onClick={handleHideSidebar} />*/}
            {/*</SidebarCategory>*/}
          {/*</ul>*/}
        <CreateList toggle={toggle} isOpen={viewCreateList}/>

        </div>
    );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
