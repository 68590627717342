import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import { faUserSlash } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../../../shared/components/Notification";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";


const ProductReviewArticlesTable = ({ dispatch, data, productPosts ,theme,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);

    useEffect(()=>{
        setLoaded(false);
        if(typeof productPosts !== 'undefined' || productPosts!==null){
            setLoaded(true);
        }
    },[productPosts]);

    const columns =[
        {
            width: 300,
            Header: "Article",
            Cell: ({ cell }) => {
                return (
                    <a
                        href={cell.row.original.po_link}
                        target="_blank"
                        rel="noopener noreferrer"  // For security reasons.
                        style={{color:"#fff", display: 'flex', alignItems: 'center'}}
                    >
                        <FontAwesomeIcon
                            icon={faNewspaper}
                            style={{color:"#f6da6e", fontSize:"1.5rem", marginRight: '10px'}} // increased marginRight
                        />
                        {cell.row.original.po_title}
                    </a>
                )
            }
        },
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (
        isTableLoaded &&
            <DataReactTable reactTableData={ tableStructure } />
    );
};

export default connect(state => ({
    data:state.product.productData,
    productPosts:state.product.productPosts,
    rtl: state.rtl,
    theme: state.theme
}))(ProductReviewArticlesTable);
