import React, { useEffect,useState,useRef  } from 'react';
import { connect } from 'react-redux';
import { Button,Row,Col, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import Alert from "../../Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Http from '../../../../containers/App/Http';
import Cleave from "cleave.js/react";
import Select from 'react-select';

import AsyncSelect from 'react-select/async';
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import {setUser} from "../../../../redux/actions/userActions";
import Authentication from "../../../../Models/Authentication";
import {Link} from "react-router-dom";
// import {setActiveVenueId} from "../../../redux/actions/userActions";
import logo from '../../../img/logo/logo_light.png';
import {Field} from "react-final-form";
import PasswordField from "../../form/Password";
import Registration from "../../../../Models/Registration";

const ModalLogin = ({dispatch, isOpen,toggle,rtl,theme}) => {
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [registerView,setRegisterView] = useState(false);
    const [registrationError,setRegistrationError] = useState(false);
    const [RegistrationSuccess,setRegistrationSuccess] = useState(false);

    const am = new Authentication();
    const rm = new Registration();

    useEffect(() => {
        setError(false);
        setSuccess(false);
    },[isOpen]);

    const handleSubmit= () =>{
        document.querySelector("#formModalLogin button[type=submit]").click();
    };

    const createNewList=async (form)=>{
        form.preventDefault();
        setProcessing(true);
        let formData = new FormData(document.getElementById('formModalLogin'));

        lsm.createNewList(formData).then(async response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);

            return true;
        });

    };

    const handleLogin = (e) => {
        e.preventDefault();
        let formData =new FormData(e.currentTarget);

        am.loginUsingFormData(formData).then(
            (response) => {
                if(response.data.status==='failed'){
                    setError(response.data.msg);
                    return;
                }
                // SET USER AND ROLES
                dispatch(setUser(response.data.data.user));

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );

    };

    const handleRegister = (e) => {
        e.preventDefault();

        let formData =new FormData(e.currentTarget);

        rm.registerUser(formData).then(
            (response) => {
                if(response.data.status==='failed') {
                    setRegistrationError(response.data.msg);
                    return;
                }
                setRegistrationError(false);
                setRegistrationSuccess(response.data.msg);
                setRegisterView(false);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );

    };

    const toggleRegisterView=()=>{
        setRegisterView(registerView => !registerView)
    };

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} id="ModalLogin" modalClassName={`${rtl.direction}-support`} size="md">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <Col xs={12} className="text-center">
                        <img src={logo} style={{maxWidth:'250px'}}/>
                    </Col>

                </div>
                <div className="modal__body">
                    {registerView &&
                        <Form onSubmit={handleRegister}>
                            <Row>
                                <Col xs={'12'}>
                                    {registrationError !==false &&
                                    <Alert color="danger">
                                        {registrationError}
                                    </Alert>
                                    }
                                    {RegistrationSuccess !== false &&
                                    <Alert className="alert alert-success">
                                        <p>{RegistrationSuccess}</p>
                                    </Alert>
                                    }
                                </Col>
                            </Row>

                            <div className="row">
                                <label className="col-sm-12 form__form-group-label col-form-label">Username</label>
                                <div className=" col-sm-12">
                                    <input name="u_username" type="text" placeholder="Username" className="form-control-sm form-control"/>
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-sm-12 form__form-group-label col-form-label">First Name</label>
                                <div className=" col-sm-12">
                                    <input name="u_first" type="text" placeholder="First Name" className="form-control-sm form-control"/>
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-sm-12 form__form-group-label col-form-label">Last Name</label>
                                <div className=" col-sm-12">
                                    <input name="u_last" type="text" placeholder="Last Name" className="form-control-sm form-control"/>
                                </div>
                            </div>

                            <div className="row">
                                <label className="col-sm-12 form__form-group-label col-form-label">E-mail</label>
                                <div className=" col-sm-12">
                                    <input name="u_email" type="email" placeholder="Email" className="form-control-sm form-control"/>
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-sm-12 form__form-group-label col-form-label">Password</label>
                                <div className=" col-sm-12">
                                    <input name="password" type="password" placeholder="Password" className="form-control-sm form-control"/>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <Col xs={12}>
                                    <button className="btn btn-primary account__btn account__btn--small" type="submit">Register</button>
                                    <button className="btn btn-primary account__btn account__btn--small" type="button" onClick={toggleRegisterView}>Back To Login</button>
                                </Col>
                            </div>
                        </Form>
                    }

                    {!registerView &&
                        <Form onSubmit={handleLogin}>
                            <Row>
                                <Col xs={'12'}>
                                    {errorMsg !==false &&
                                    <Alert color="danger">
                                        {errorMsg}
                                    </Alert>
                                    }
                                    {successMsg !== false &&
                                    <Alert className="alert alert-success">
                                        <p>{successMsg}</p>
                                    </Alert>
                                    }
                                </Col>
                            </Row>

                            <div className="row">
                                <label className="col-sm-12 form__form-group-label col-form-label">E-Mail</label>
                                <div className=" col-sm-12">
                                    <input name="username" type="email" placeholder="E-Mail" className="form-control-sm form-control"/>
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-sm-12 form__form-group-label col-form-label">Password</label>
                                <div className=" col-sm-12">
                                    <input name="password" type="password" placeholder="Password" className="form-control-sm form-control"/>
                                </div>
                            </div>
                            <Row className="mt-4">
                                <Col xs={12}>
                                    <button className="btn btn-primary account__btn account__btn--small" type="submit">Sign in</button>
                                    <button className="btn btn-dark account__btn account__btn--small" type="button" onClick={toggleRegisterView}>Register</button>
                                </Col>
                            </Row>
                        </Form>
                    }
                </div>
            </Modal>
        </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
}))(ModalLogin);