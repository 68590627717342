import React,{ useEffect,useState } from 'react';
import Panel from "../../Panel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelProducts from '../../../../Models/Products';
import {Link, useHistory} from "react-router-dom";
import {faExternalLinkAlt, faEye, faHotel, faPen} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import DataTable from "../../DataTable";

const ProductsTable = ({ dispatch, title, limit}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [submission,setSelectedSubmission] = useState(null);
    const um = new ModelProducts();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const columns =[
        {
            width: 50,
            Header: "Image",
            Cell: ({ cell }) => {
                // debugger
                return (
                    <>
                        {cell.row.original.p_featured_image===null &&
                        <img style={{borderRadius:'10px', maxWidth:'100px'}} src="https://picsum.photos/300/200" alt="Featured Image" />
                        }
                        {cell.row.original.p_featured_image!==null &&
                        <img style={{borderRadius:'10px', maxWidth:'100px'}} src={process.env.REACT_APP_BASE_API_URL +"/assets/images/"+cell.row.original.p_featured_image} alt="" />
                        }

                    </>
                )
            }
        },{

            Header: "Name",
            Cell: ({ cell }) => {
                // debugger
                return (
                    <>
                        <p style={{cursor:'pointer',maxWidth: '800px'}} className={ "m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/product/'+cell.row.original.p_id)}}>
                            {cell.row.original.p_name}
                        </p>
                    </>
                )
            }
        },
        {
            width: 300,
            Header: "Buy",
            Cell: ({ cell }) => {
                // debugger
                let src;
                if(cell.row.original.s_advanced_id==="AMZN")
                    src="/img/amazonLogoSmallForDarkTheme.png";
                return (
                    <a href={cell.row.original.pl_link} onClick={e => {e.preventDefault();window.open(cell.row.original.pl_link+"?tag="+process.env.REACT_APP_AMAZON_ASSOCIATE_ID, '_blank');}}>
                        <img src={src} style={{maxWidth:"100px"}}/>
                    </a>
                )
            }
        },
        {
            Header: 'Model',
            accessor: 'p_model',
            width: 150
        }
    ];

    return (

        <Panel xl={12} lg={12} md={12} title={"Featured Products"} subhead="Table of products">
            <div>
                <DataTable columns={columns} endpoint={"/ps/getProductsForDatatable"} classNames={"table--bordered"}/>
            </div>
        </Panel>

    );
};

export default connect(state => ({
}))(ProductsTable);
