import { handleActions } from 'redux-actions';
import {
    setListsResults
    ,setProductResults
} from '../actions/searchActions';

const defaultState = {
    productResults: null,
    listsResults: null
};

export default handleActions(
  {
      [setProductResults](state, { payload }) {
          return { ...state, productResults: payload };
      },
      [setListsResults](state, { payload }) {
          return { ...state, listsResults: payload };
      }
  },
  defaultState,
);
