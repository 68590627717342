import Http from '../containers/App/Http';
import store from '../containers/App/store'
// import {setAllUsers} from "../redux/actions/usersActions";
import {setRoles, setUser} from "../redux/actions/userActions";

export default class Registration{

    registerUser(formData){
        // FETCH THE Users
        // const formData = new FormData();

        return Http.post("/register/registerNewUser",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            //await this.setUserRedux(response.data.data,response.data.data.roles);
            return response;
        });
    };

    // async setUserRedux(userObject,rolesObject){
    //     if(typeof userObject !== "undefined")
    //         await store.dispatch(setUser(userObject));
    //     if(typeof rolesObject !== "undefined")
    //         await store.dispatch(setRoles(rolesObject));
    // };

}