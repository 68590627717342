import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {
    setProduct,
    setFeatures,
    setSpecifications,
    setPriceTrackerData,
    setRatingTrackerData,
    setLinks,
    setCategories,
    setImages,
    setPriceExtended,
    setRatingExtended,
    setCategoriesExtended
} from "../redux/actions/productActions";
import {setListsResults, setProductResults} from "../redux/actions/searchActions";

export default class Search{

    globalSearch(term){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('term',term);

        return Http.post("/s",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setProductResults(response.data.data[0]));
            await store.dispatch(setListsResults(response.data.data[1]));

            return response;
        });
    };

}