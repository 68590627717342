import React, { useEffect,useState,useRef  } from 'react';
import { connect } from 'react-redux';
import { Button,Row,Col, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import Alert from "../../../shared/components/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Http from '../../../../src/containers/App/Http';
import Lists from "../../../Models/Lists/Lists";
import Cleave from "cleave.js/react";
import Select from 'react-select';

import AsyncSelect from 'react-select/async';
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import ModalLogin from "../Login/ModalLogin";
import Authentication from "../../../Models/Authentication";
// import {setActiveVenueId} from "../../../redux/actions/userActions";

const CreateList = ({dispatch, user,isOpen,toggle,rtl,theme}) => {
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [authed,setIsAuthed] = useState(false);

    const lsm = new Lists();
    const auth = new Authentication();

    useEffect(() => {
        if(auth.isAuthenticated())
            setIsAuthed(true);
        else
            setIsAuthed(false);

    },[user]);

    useEffect(() => {

    },[]);

    const handleSubmit= () =>{
        document.querySelector("#formCreateList button[type=submit]").click();
    };

    const createNewList=async (form)=>{
        form.preventDefault();
        setProcessing(true);
        let formData = new FormData(document.getElementById('formCreateList'));

        lsm.createNewList(formData).then(async response => {

            if (response.data.status === 'failed') {
                setProcessing(false);
                setError(response.data.msg);
                setSuccess(false);
                return false;
            }

            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);

            return true;
        });

    };

    return (
        <>

            {!authed &&
                <ModalLogin toggle={toggle} isOpen={isOpen}/>
            }
            {authed &&
            <Modal isOpen={isOpen} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} id="ModalRequestAccess" modalClassName={`${rtl.direction}-support`} size="md">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Create List</h4>
                </div>

                <div className="modal__body">

                    <form id="formCreateList" className={"form--horizontal form"} onSubmit={createNewList}>
                        <button type="submit" style={{'display': 'none'}}>Submit</button>

                        <Col xs={'12'}>
                            {errorMsg !==false &&
                            <Alert color="danger">
                                {errorMsg}
                            </Alert>
                            }
                            {successMsg !== false &&
                            <Alert className="alert alert-success">
                                <p>{successMsg}</p>
                            </Alert>
                            }
                        </Col>


                        <div className="form__form-group">
                            <label htmlFor="l_description" className="col-sm-12 form__form-group-label col-form-label">List Name</label>
                            <div className=" col-sm-12">
                                <input name="l_name" type="text" placeholder="List Name" className="form-control-sm form-control"/>
                            </div>
                        </div>
                        <div className="form__form-group">
                            <label htmlFor="l_description" className="col-sm-2 form__form-group-label col-form-label">Description</label>
                            <div className="col-sm-12">
                                <textarea className="form-control-sm form-control" id="l_description" name="l_description"></textarea>
                            </div>

                        </div>

                        <div className="col-sm-12">
                            <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit} disabled={processing}>Create</button>
                        </div>

                    </form>

                </div>

            </Modal>
            }

        </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    user: state.user.main
}))(CreateList);