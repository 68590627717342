import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useHistory} from 'react-router-dom';
import Authentication from "../../../Models/Authentication";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faSearch, faSlash} from "@fortawesome/free-solid-svg-icons";
import SearchModel from "../../../Models/Search";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";

const Search = ({ user,classes }) => {

    const [authed,setIsAuthed] = useState(false);
    const [term,setTerm] = useState("");
    const auth = new Authentication();
    const sm = new SearchModel();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const updateTerm = (e) => {
        setTerm(e.target.value);
    };

    const submitSearch = (e) => {
        e.preventDefault();
        redirect('/s?term='+ term);
    };

    return (
        <form className={"form-inline "+ classes} onSubmit={submitSearch}>
            <div className="form__form-group-field">
                <input type="text" className="" placeholder={"Search Anything"} onChange={updateTerm} value={term}/>
                <div className="form__form-group-icon">
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </div>
            </div>
        </form>
    )

};

export default connect(state => ({
    user : state.user.main
}))(Search);
